<template>
  <div class="d-flex flex-row justify-content-end no-print">
    <slot name="beforeButtons" />
    <span
      v-if="view"
      href="#"
      class="text-primary cursor-pointer mr-1"
      @click="$emit('view')"
    >
      <feather-icon
        icon="EyeIcon"
      />
    </span>
    <slot name="afterViewButton" />
    <span
      v-if="edit"
      href="#"
      class="text-warning cursor-pointer mr-1"
      @click="$emit('edit')"
    >
      <feather-icon
        icon="EditIcon"
      />
    </span>
    <slot name="afterEditButton" />
    <span
      v-if="del"
      href="#"
      class="text-danger cursor-pointer"
      @click="deleteAction"
    >
      <feather-icon
        icon="TrashIcon"
      />
    </span>
    <slot name="afterButtons" />
  </div>
</template>
<script>

export default {
  props: {
    view: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    del: {
      type: Boolean,
      default: true,
    },
    confirm: {
      type: Boolean,
      default: true,
    },
    confirmMessage: {
      type: String,
      default: 'Удалить запись?',
    },
  },
  methods: {
    deleteAction() {
      if (!this.confirm) {
        this.$emit('delete')
      } else {
        this.$bvModal.msgBoxConfirm(
          this.confirmMessage, {
            centered: true,
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
          },
        )
          .then(status => {
            if (status) {
              this.$emit('delete')
            }
          })
      }
    },
  },
}
</script>
